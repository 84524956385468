import { ImportedDataStoreImpl } from "../imported-data";

function fixUnits(data: ImportedDataStoreImpl): void {
    {
    }
}

export function overrideSylvaneth(data: ImportedDataStoreImpl): void {
    fixUnits(data);
}
