import { ImportedDataStoreImpl } from "../imported-data";
// import { addAbilityEffect } from "./tools";
// import { TargetType, Phase } from "../unit";

export function overrideGitz(data: ImportedDataStoreImpl) {
    // addAbilityEffect(data.abilities.rockgutTroggothsRegeneration, {
    //     targetType: TargetType.Model,
    //     phase: Phase.Hero,
    // });
    // addAbilityEffect(data.abilities.rockgutTroggothsStonySkin, {
    //     targetType: TargetType.Model,
    //     defenseAura: {},
    // });
    // addAbilityEffect(data.abilities.rockgutTroggothsThrowinBoulders, {
    //     targetType: TargetType.Enemy,
    //     phase: Phase.Shooting,
    // });
}
